/* lightGallery - http://sachinchoolur.github.io/lightGallery/ */
$(document).ready(function() {

    $('.opengallery').on('click', function(e) {
        e.preventDefault();
        var dataJson = $(this).data("json"); //vzimame celiq json masiv

        if (dataJson) {
            $(this).lightGallery({
                download: false,
                dynamic: true,
                dynamicEl: dataJson // vij http://sachinchoolur.github.io/lightGallery/demos/dynamic.html
            })
        }

    });
});


/* Flare Lightbox - DEPRECATED */
/*
jQuery(function($){
                $('a[data-target="flare"]').peFlareLightbox();
        });*/
