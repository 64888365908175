// To keep our code clean and modular, all custom functionality will be contained inside a single object literal called "checkboxFilter".

var checkboxFilter = {

    // Declare any variables we will need as properties of the object

    $filters: null,
    $reset: null,
    groups: [],
    outputArray: [],
    outputString: '',

    // The "init" method will run on document ready and cache any jQuery objects we will need.

    init: function(){
        var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "checkboxFilter" object so that we can share methods and properties between all parts of the object.

        self.$filters = $('#Filters');
        self.$reset = $('#Reset');
        self.$container = $('#Container');

        self.$filters.find('fieldset').each(function(){
            self.groups.push({
                $inputs: $(this).find('input'),
                active: [],
                tracker: false
            });
        });

        self.bindHandlers();
    },

    // The "bindHandlers" method will listen for whenever a form value changes.

    bindHandlers: function(){
        var self = this;

        self.$filters.on('change', function(){
            self.parseFilters();
        });

        self.$reset.on('click', function(e){
            e.preventDefault();
            self.$filters[0].reset();
            self.parseFilters();
        });
    },

    // The parseFilters method checks which filters are active in each group:

    parseFilters: function(){
        var self = this;

        // loop through each filter group and add active filters to arrays

        for(var i = 0, group; group = self.groups[i]; i++){
            group.active = []; // reset arrays
            group.$inputs.each(function(){
                $(this).is(':checked') && group.active.push(this.value);
            });
            group.active.length && (group.tracker = 0);
        }

        self.concatenate();
    },

    // The "concatenate" method will crawl through each group, concatenating filters as desired:

    concatenate: function(){
        var self = this,
            cache = '',
            crawled = false,
            checkTrackers = function(){
                var done = 0;

                for(var i = 0, group; group = self.groups[i]; i++){
                    (group.tracker === false) && done++;
                }

                return (done < self.groups.length);
            },
            crawl = function(){
                for(var i = 0, group; group = self.groups[i]; i++){
                    group.active[group.tracker] && (cache += group.active[group.tracker]);

                    if(i === self.groups.length - 1){
                        self.outputArray.push(cache);
                        cache = '';
                        updateTrackers();
                    }
                }
            },
            updateTrackers = function(){
                for(var i = self.groups.length - 1; i > -1; i--){
                    var group = self.groups[i];

                    if(group.active[group.tracker + 1]){
                        group.tracker++;
                        break;
                    } else if(i > 0){
                        group.tracker && (group.tracker = 0);
                    } else {
                        crawled = true;
                    }
                }
            };

        self.outputArray = []; // reset output array

        do{
            crawl();
        }
        while(!crawled && checkTrackers());

        self.outputString = self.outputArray.join();

        // If the output string is empty, show all rather than none:

        !self.outputString.length && (self.outputString = 'all');

        //console.log(self.outputString);

        // ^ we can check the console here to take a look at the filter string that is produced

        // Send the output string to MixItUp via the 'filter' method:

        if(self.$container.mixItUp('isLoaded')){
            self.$container.mixItUp('filter', self.outputString);
        }
    }
};

// On document ready, initialise our code.
// ---> vuv kareta1.blade.php


jQuery(document).ready(function($){

    //=========================================================================================
    // COOKIE SETUP
    //=========================================================================================
    function createCookie(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name,"",-1);
    }

    function compareArrsForCommonValue(arr1, arr2){
        var matched = false;
        $(arr1).each( function( arr1index, arr1value ) {
            $(arr2).each( function( arr2index, arr2value ) {
                if (arr1value == arr2value ) {
                    //alert('Namerihme ravni: ' + arr1value + ' i'+ arr2value)
                    matched = true;
                    return false; //izlizame ot loop-a, kogato ima edno suvpadenie
                }
            });
            return false;
        });
        if (matched) {
            return true;
        } else {
            return false;
        }
    }

    //Pri click na subcategory ili gender checkbox
    $('.filter_controls input').on('click', function () {
        var key = $(this).closest('.filter_controls').attr('data-filter');

        //Vzemi value ot vsichki checknati butoni
        var values = [];
        $.each($(".filter_controls input[type='checkbox']:checked"), function(){
            values.push($(this).val());
        });
        //alert("Checked classes are: " + values.join(", "));

        var value = values.join(", "); // .cat1, .cat2, .gender1

        //ako ima kliknati checkboxove suzdai cookie, ako gi otmarkira vsichki iztrii cookie
        if( value ) {
            createCookie(key, value);
        } else {
            eraseCookie(key);
        }

        //var myCookie = readCookie(key);
        //console.log(myCookie); // Outputs: "The value of my cookie..."
    });


    // Initialize checkboxFilter code
    checkboxFilter.init();

    // key csrf token, koeto e i ime na cookie
    var key = $('.filter_controls').attr('data-filter');
    var myCookie = readCookie(key);

    //Proveriavame dali cookieto otgovarq na suotvetnata kategoriq - ski-obleklo, koloezdene
    //opravq problema da se zapazi filtera na ski obleklo, no posle se opita da vlezne v koloezdene
    //i ne mu izlizat kutiiki, zashtoto zarejda filter sus ski kategoriite
    //Proveriavame dali imat obshta stoinost dvata masiva, ako imat propusni cookie, ako nqmat iztrii cookie
    if (myCookie) {
        var arrCookie = myCookie.split(", ");
        var arrCheckboxClasses = [];
        $.each($(".filter_controls input[type='checkbox']"), function(){
            arrCheckboxClasses.push($(this).val());
        });

        var areTheyCommon = compareArrsForCommonValue(arrCookie, arrCheckboxClasses); //ako ima obshto true, ako nqmat false

        if (areTheyCommon === false) {
            // iztrii cookie i myCookie = false
            eraseCookie(key);
            myCookie = false;
        }
    }

    // Ako ima zapazen filter (cookie) zaredi ot cookieto, ako ne - pokajo vsichki i iztrii cookie
    if (myCookie) {
        // markirame subcategories izbrani kato checked
        var valarr = myCookie.split(", "); // arr[.cat1,.cat2]

        $.each(valarr, function(index,value){
            /// alert(value);
            $('.filter_controls input[type=checkbox][value="' + value +'"]').prop('checked', true);
        });

        // Instantiate MixItUp with Filters
        $('#Container').mixItUp({
            layout: {
                //   display: 'block'
            },
            load: {
                filter: myCookie
            },
            controls: {
                enable: false // we won't be needing these
            },
            callbacks: {
                onMixFail: function(){
                    // alert('No items were found matching the selected filters.');
                }
            }
        });
    } else {
        // iztrii cookie
        eraseCookie(key);
        // Instantiate MixItUp
        $('#Container').mixItUp({
            layout: {
                //   display: 'block'
            },
            controls: {
                enable: false // we won't be needing these
            },
            callbacks: {
                onMixFail: function(){
                    // alert('No items were found matching the selected filters.');
                }
            }
        });
    }

});






